@import '../../../assets/scss/base.scss';

header {
  & > .ant-row {
    & > .header-column:nth-child(1),
    & > .header-column:nth-child(3) {
      @include respondAbove(xs) {
        flex: 0 0 20%;
      }
    }
  }
  &.navbar-dashboard,
  &.navbar-admin {
    & > .ant-row {
      .nav-bar-logo {
        display: none;
      }
    }
    .navbar {
      &-username {
        display: inline-block;
        max-width: 400px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
}
.avatar {
  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.ant-popover {
  .ant-popover-inner-content {
    padding: 0;
    .message-popover {
      max-width: 350px;
      margin-right: 15px;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-spin-container {
    max-height: 500px;
    overflow-y: auto;
    @media only screen and (max-width: $tablet) {
      max-height: 365px;
    }
  }
}
.notification {
  &-item {
    padding: 0 !important;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
    .ant-tag {
      width: 100%;
      display: flex;
      flex: 1 1;
      align-items: flex-start;
      padding: 12px 0 12px 20px;
      flex-direction: column;
      border: 0;
      cursor: pointer;
      @media only screen and (max-width: $tablet) {
        padding: 6px 0 6px 10px;
        font-size: 80%;
      }
      &.hasRead {
        opacity: 0.6;
        pointer-events: none;
        margin: 0;
      }
      &:not(.hasRead) {
        border-left: 2px solid green;
      }
    }
    .message-container {
      white-space: normal;
    }
    .related-message {
      color: red;
    }
    .related-name {
      color: black;
    }
    .title {
      white-space: normal;
      color: black;
      font-weight: bold;
    }
    .content {
      flex-direction: row;
      flex-wrap: wrap;
      white-space: normal;
      justify-content: flex-start;
      color: black;
      span {
        margin: 0 4px;
      }
      .field-name {
        font-style: italic;
        font-weight: bold;
      }
      .old-value {
        color: red;
      }
      .new-value {
        color: green;
      }
    }
    .date {
      color: #555;
      text-align: right;
      width: 100%;
      font-style: italic;
    }
  }
  .ant-list {
    &-footer {
      text-align: center;
    }
  }
}

.navbar-action-list {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  justify-content: flex-end;
  li {
    list-style: none;
    height: 100%;
    cursor: pointer;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}
