// @import '~antd/es/style/themes/default.less';

.mini-progress {
  position: relative;
  width: 100%;
  padding: 5px 0;
  .progress-wrap {
    display: flex;
    position: relative;
    background-color: #f5f5f5;
  }
  .progress {
    width: 0;
    height: 100%;
    background-color: #3fac44;
    border-radius: 1px 0 0 1px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    text-align: center;
    color: #fff;
    overflow: hidden;
  }
  .unprogress {
    width: 0;
    height: 100%;
    text-align: center;
    color: #000;
    overflow: hidden;
  }
  .target {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9;
    width: 20px;
    span {
      font-size: 9px !important;
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 4px;
      border-radius: 100px;
      color: #000000;
    }
    span:last-child {
      top: auto;
      bottom: 0;
    }
  }
}
