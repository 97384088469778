@import '../base.scss';
.no-pad {
  padding: 0px !important;
}
.pad-sm {
  padding: 16px !important;
}
.pad-md {
  padding: 32px !important;
  @media only screen and (max-width: $tablet) {
    padding: 24px !important;
  }
  @media only screen and (max-width: $mobile) {
    padding: 16px !important;
  }
}
.pad-lg {
  padding: 48px !important;
  @media only screen and (max-width: $tablet) {
    padding: 40px !important;
  }
  @media only screen and (max-width: $mobile) {
    padding: 32px !important;
  }
}
.pad-top {
  padding-top: 32px !important;
  @media only screen and (max-width: $mobile) {
    padding-top: 24px !important;
  }
}
.pad-top-xs {
  padding-top: 8px !important;
}
.pad-top-sm {
  padding-top: 16px !important;
}
.pad-top-md {
  padding-top: 24px !important;
  @media only screen and (max-width: $mobile) {
    padding-top: 16px !important;
  }
}

.pad-top-lg {
  padding-top: 48px !important;
  @media only screen and (max-width: $tablet) {
    padding-top: 40px !important;
  }
  @media only screen and (max-width: $mobile) {
    padding-top: 32px !important;
  }
}

.pad-vertical-lg {
  padding: 48px 0 !important;
  @media only screen and (max-width: $tablet) {
    padding: 40px 0 !important;
  }
  @media only screen and (max-width: $mobile) {
    padding: 32px 0 !important;
  }
}
.pad-vertical-sm {
  padding: 16px 0 !important;
}
.pad-vertical-xs {
  padding: 8px 0 !important;
}
.pad-vertical-md {
  padding: 24px 0 !important;
  @media only screen and (max-width: $tablet) {
    padding: 16px 0 !important;
  }
}
.pad-horizontal-xs {
  padding: 0 8px !important;
}
.pad-horizontal-sm {
  padding: 0 16px !important;
}
.pad-horizontal-md {
  padding: 0 24px !important;
}
.pad-bot {
  padding-bottom: 32px !important;
  @media only screen and (max-width: $mobile) {
    padding-bottom: 24px !important;
  }
}
.pad-bot-xs {
  padding-bottom: 8px !important;
}
.pad-bot-sm {
  padding-bottom: 16px !important;
}
.pad-bot-md {
  padding-bottom: 24px !important;
  @media only screen and (max-width: $mobile) {
    padding-bottom: 16px !important;
  }
}
.pad-bot-lg {
  padding-bottom: 48px !important;
  @media only screen and (max-width: $tablet) {
    padding-bottom: 40px !important;
  }
  @media only screen and (max-width: $mobile) {
    padding-bottom: 32px !important;
  }
}
.mar-top {
  margin-top: 32px !important;
  @media only screen and (max-width: $mobile) {
    margin-top: 24px !important;
  }
}
.mar-top-xs {
  margin-top: 8px !important;
}
.mar-top-sm {
  margin-top: 16px !important;
}
.mar-left {
  margin-left: 32px !important;
}
.mar-left-md {
  margin-left: 24px !important;
}
.mar-left-sm {
  margin-left: 16px !important;
}
.mar-left-xs {
  margin-left: 8px !important;
}
.mar-right {
  margin-right: 32px !important;
}
.mar-right-xs {
  margin-right: 8px !important;
}
.mar-right-sm {
  margin-right: 16px !important;
}
.mar-top-md {
  margin-top: 24px !important;
  @media only screen and (max-width: $mobile) {
    margin-top: 16px !important;
  }
}
.mar-top-lg {
  margin-top: 48px !important;
  @media only screen and (max-width: $tablet) {
    margin-top: 40px !important;
  }
  @media only screen and (max-width: $mobile) {
    margin-top: 32px !important;
  }
}
.mar-bot {
  margin-bottom: 32px !important;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 24px !important;
  }
}
.mar-bot-xs {
  margin-bottom: 8px !important;
}
.mar-bot-sm {
  margin-bottom: 16px !important;
}
.mar-bot-md {
  margin-bottom: 24px !important;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 16px !important;
  }
}
.mar-bot-lg {
  margin-bottom: 48px !important;
  @media only screen and (max-width: $tablet) {
    margin-bottom: 40px !important;
  }
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px !important;
  }
}
.pad-left {
  padding-left: 32px !important;
}
.pad-left-xs {
  padding-left: 8px;
}
.pad-left-sm {
  padding-left: 16px !important;
}
.pad-left-md {
  padding-left: 24px !important;
}
.pad-right {
  padding-right: 32px !important;
}
.pad-right-xs {
  padding-right: 8px;
}
.pad-right-sm {
  padding-right: 16px !important;
}
.pad-right-md {
  padding-right: 24px !important;
}

.mar-vertical-sm {
  margin: 16px 0 !important;
}

.mar-vertical-md {
  margin: 24px 0 !important;
  @media only screen and (max-width: $mobile) {
    margin: 16px 0 !important;
  }
}
.mar-vertical-lg {
  margin: 48px 0 !important;
  @media only screen and (max-width: $tablet) {
    margin: 40px 0 !important;
  }
  @media only screen and (max-width: $mobile) {
    margin: 32px 0 !important;
  }
}

.mar-horizontal-sm {
  margin: 0 16px !important;
}

.mar-horizontal-md {
  margin: 0 24px !important;
  @media only screen and (max-width: $mobile) {
    margin: 0 16px !important;
  }
}
.mar-horizontal-lg {
  margin: 0 48px !important;
  @media only screen and (max-width: $tablet) {
    margin: 0 40px !important;
  }
  @media only screen and (max-width: $mobile) {
    margin: 0 32px !important;
  }
}
.reset-mar {
  margin: 0 !important;
}
.reset-pad {
  padding: 0 !important;
}

// grid
.row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
  &.center {
    justify-content: center;
  }
  &.middle {
    align-items: center;
  }
  &.end {
    justify-content: flex-end;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.column {
    flex-direction: column;
  }
  @media only screen and (max-width: $mobile) {
    &.break-xs {
      display: block;
    }
  }
  [class*='col-'] {
    padding: 8px;
  }
  .col-1-8 {
    width: 12.5%;
  }
  .col-2-8 {
    width: 25%;
  }
  .col-3-8 {
    width: 37.5%;
  }
  .col-4-8 {
    width: 50%;
  }
  .col-5-8 {
    width: 62.5%;
  }
  .col-6-8 {
    width: 75%;
  }
  .col-7-8 {
    width: 87.5%;
  }
  .col-8-8 {
    width: 100%;
  }
  .col-offset-1-8 {
    margin-left: 12.5%;
  }
  .col-offset-2-8 {
    margin-left: 25%;
  }
  .col-offset-3-8 {
    margin-left: 37.5%;
  }
  .col-offset-4-8 {
    margin-left: 50%;
  }
  .col-offset-5-8 {
    margin-left: 62.5%;
  }
  .col-offset-6-8 {
    margin-left: 75%;
  }
  .col-offset-7-8 {
    margin-left: 87.5%;
  }
  .col-offset-8-8 {
    margin-left: 100%;
  }
  .col-xs-8-8 {
    @media only screen and (max-width: $mobile) {
      width: 100%;
    }
  }
  .reset-offset-xs {
    @media only screen and (max-width: $mobile) {
      margin-left: 0;
    }
  }
}

.flex1 {
  flex: 1;
}
.flex01 {
  flex: 0.1;
}
.flex02 {
  flex: 0.2;
}
.flex03 {
  flex: 0.3;
}
.flex04 {
  flex: 0.4;
}
.flex05 {
  flex: 0.5;
}
.flex06 {
  flex: 0.6;
}
.flex07 {
  flex: 0.7;
}
.flex08 {
  flex: 0.8;
}
.flex09 {
  flex: 0.9;
}

.col-half {
  &.no-gap {
    padding: 0px;
  }

  &.flex {
    display: flex;

    &.column {
      flex-direction: column;
    }

    &.space-between {
      justify-content: space-between;
    }
  }

  vertical-align: top;
  display: inline-block;
  width: 50%;
  padding: 8px;
  @media only screen and (max-width: $mobile) {
    width: 100%;
  }
}
.flex-row {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  &.column {
    flex-direction: column;
  }
}

.flex-space {
  display: flex;
  justify-content: space-between;
  &.center {
    align-items: center;
  }
  &.half {
    flex: 0.5;
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-start {
  align-items: flex-start;
}
