@import '../../assets/scss/base.scss';

.ant-table-header,
.ant-table-body {
  colgroup col {
    width: 150px !important;
    min-width: 150px !important;
  }
  th {
    width: 150px;
  }
}
