@import '../../assets/scss/base.scss';

.gantt_task_line .gantt_milestone {
  display: none !important;
}
.hide_project_progress_drag .gantt_task_progress_drag {
  visibility: hidden;
}
.hide_link_drag .gantt_link_control {
  display: none !important;
}
.gantt_task_progress {
  text-align: left;
  // padding-left: 10px;
  box-sizing: border-box;
  color: white;
  font-weight: bold;
}
.gantt {
  height: 100%;
  width: 100%;
}
.gantt_grid_data {
  .gantt_parent_row {
    .gantt_cell {
      .gantt_tree_content {
        font-weight: bold;
        color: $green;
      }
    }
  }
}

.start_status_line {
  background-color: #0ca30a;
}
.today_line {
  background-color: #989898;
  // .gantt_marker_content {
  //   color: black;
  // }
}
.end_status_line {
  background-color: red;
}
.gantt-zoom-bar {
  background: #ededed;
  height: 40px;
  line-height: 40px;
  padding: 5px 10px;
}
.zoom-bar {
  position: relative;
  background-color: whitesmoke;
  .gantt-controls {
    min-height: 40px;
    line-height: 40px;
    .gantt-column-control {
      // min-height: 40px;
      // position: absolute;
      // left: 0;
      // bottom: 0px;
      // z-index: 100;
      button {
        font-weight: bold;
      }
      .ant-select-selector {
        padding: 0;
        border: 0;
        .ant-select-selection-item {
          height: 40px;
          align-items: center;
          justify-content: center;
          margin: 0;
          border: 0;
        }
      }
    }
  }
}

.radio-label {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 28px;
  margin-left: 6px;
  text-align: center;
  width: 70px;

  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #fff;
  color: rgba(0, 0, 0, 0.7);
}

.radio-label > input[type='radio'] {
  height: 0;
  margin: 0;
  visibility: hidden;
  width: 0;
}

.radio-label-active {
  background: #a0a0a0;
  color: white;
  border-color: #707070;
}
.zoom-bar {
  background-color: #fff;
  // border-top: 1px solid #cecece;
  // border-left: 1px solid #cecece;
  // border-right: 1px solid #cecece;
  @media only screen and (max-width: $tablet) {
    min-height: 80px;
  }
  .gantt-controls {
    @media only screen and (max-width: $tablet) {
      // position: fixed;
      // width: calc(100% - 10px);
      // left: 0;
      .gantt-menu-item-right {
        float: none;
      }
    }
  }
}
.gantt-controls {
  background-color: #f5f5f5;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gantt-menu-item {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  font-weight: 600;
  overflow: visible;
  /* padding: 0 10px; */
  .menu-item-active {
    background-color: #dadada !important;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .menu-item-disabled {
    color: #818181 !important;
    pointer-events: none;
  }
  a {
    display: block;
    cursor: pointer;
    color: #3d3d3d;
    text-align: center;
    line-height: 40px;
    padding: 0 10px;
    text-decoration: none;
    span {
      padding-right: 8px;
    }
  }
  a:hover {
    background-color: #e2e2e2;
  }
}
.gantt-menu-item-right {
  float: right;
  line-height: 40px;
}
.gantt-menu-item:hover .gantt-controls {
  display: block;
  left: 0;
  padding: 5px 0;
  position: absolute;
  top: 41px;
  z-index: 1;
}
.task-status {
  margin-left: 18px;
}
.task-delayed {
  width: 18px;
  margin-top: 5px;
  height: 18px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 9px;
  color: white;
  background: rgb(255, 60, 60);
  line-height: 18px;
  text-align: center;
  font-weight: 900;
  font-size: 14px;
}
.task-delaying {
  width: 18px;
  margin-top: 5px;
  height: 18px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 9px;
  color: white;
  background: #e8b81a;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  font-weight: 900;
}
.task-completed {
  font-size: 18px;
  color: green;
  width: 18px;
  font-weight: 900;
}

.root-task {
  position: absolute;
  top: 0;
  // border-radius: 2px;
  opacity: 0.7;
  margin-top: -6px;
  height: 6px;
  background: black;
  border: 1px solid black;
  &-text-left {
    font-size: 11px;
    position: absolute;
    left: -60px;
    text-align: end;
    /* bottom: -6px; */
    /* top: -3px; */
    line-height: 12px;
  }
  &-text-right {
    font-size: 11px;
    position: absolute;
    right: -60px;
    line-height: 12px;
  }
}
.is-root .gantt_link_point {
  display: none !important;
}
// .gantt_task_line .has-baseline {
//   margin-top: 20px;
// }

.gantt_side_content {
  // margin-bottom: 7px;
  &.gantt_link_crossing {
    bottom: -16px;
  }
}

// .gantt_task_link .gantt_link_arrow {
//   margin-top: 5px;
// }

.gantt_grid_head_cell.gantt_grid_head_add {
  display: none !important;
  pointer-events: none;
  opacity: 0;
}
.gantt_grid_head_cell.gantt_grid_head_status {
  text-indent: -18px;
  overflow: initial;
}

.gantt_grid_head_cell {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 140%;
  white-space: normal !important;
  &.gantt_grid_head_text {
    cursor: pointer;
  }
}

.gantt-task-container {
  height: 100%;
  width: 100%;
  display: inline-flex;
  .task-text {
    width: 100%;
    text-align: center;
    position: absolute;
  }
  .delayed-part {
    background-color: red;
    height: 100%;
    border: 1px solid red;
  }
}
.baseline {
  position: absolute;
  border-radius: 2px;
  opacity: 0.6;
  margin-top: -7px;
  // border: 1px solid rgb(255, 153, 0);
}
.stripes {
  background-size: 8px 8px;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.7) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0.7) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar {
  background-color: #a1a29f;
  box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
  display: inline-block;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
}
.success {
  background-color: #5cb85c;
}
.gantt_task_line {
  &.danger {
    border: 1px solid #f77371;
    color: #fe8c8b;
    background: #fe8c8b;
    &.gantt_selected {
      box-shadow: 0 0 5px #f77371;
    }
  }
  &.danger .gantt_task_progress {
    background: #f45e5c;
  }
  &.gantt_critical_task:not(.is-root) {
    background-color: #e63030 !important;
    border-color: #9d3a3a !important;
    &.gantt_selected {
      box-shadow: 0 0 5px #9d3a3a !important;
    }
    .gantt_task_progress {
      background: #9d3a3a;
    }
  }
}
.zoom-option {
  width: 110px !important;
  font-size: 14px !important;
  // text-align: end !important;
  color: $green !important;
  font-weight: 600 !important;
}
.select-long-text {
  min-width: 220px !important;
}

/* baselines */
.baseline {
  position: absolute;
  border-radius: 2px;
  opacity: 0.6;
  margin-top: -7px;
  height: 12px;
  background: #ffd180;
  border: 1px solid rgb(255, 153, 0);
}

/* move task lines upper */
.gantt_task_line,
.root-task-wrapper.gantt_task_line,
.gantt_line_wrapper {
  margin-top: -9px;
}

.gantt_side_content {
  &.gantt_right,
  &.gantt_left {
    white-space: pre-wrap;
    // display: flex;
    align-items: center;
    overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
    // line-height: 125%;
    // letter-spacing: 0px;
    min-height: 28px;
    text-align: right;
    font-size: 10px;
    // width: 180px;
  }
  &.gantt_right {
    text-align: left;
    padding-left: 16px;
  }
  &.gantt_left {
    text-align: right;
    padding-right: 16px;
  }
}

.gantt_task_link .gantt_link_arrow {
  margin-top: -12px;
}

.gantt_layout_cell_border_left {
  border-left: 0;
}
.gantt_layout_cell_border_right {
  // border-right: 0;
}
.gantt_layout_cell_border_bottom {
  border-bottom: 0;
}
.gantt_layout_cell_border_top {
  border-top: 1px solid #ebebeb;
}
.gantt_grid_column_resize_wrap .gantt_grid_column_resize {
  background-color: #ebebeb;
}
.gantt_grid_scale,
.gantt_task_scale {
  border-bottom-color: #ebebeb;
}
.gantt_grid_scale {
  .gantt_grid_head_cell {
    font-weight: bold;
    color: #454545;
  }
}
.other_department .gantt_add {
  display: none !important;
}

.gantt-layout-control {
  display: flex !important;
  align-items: center;
  min-height: 40px;
}

.multiline-text {
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 125%;
  letter-spacing: 0px;
  min-height: 40px;
  padding: 0.5em 0;
  text-align: left;
}

.gantt_cell {
  &[data-column-name='assignment_department'],
  &[data-column-name='assigned_department'] {
    & div {
      text-align: center;
    }
  }
}

.gantt-layout-controls {
  display: flex;
  position: absolute;
  list-style: none;
  margin-bottom: 0;
  z-index: 100;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  margin-left: -1px;
  transition: all 0.6s;
  &.--isActive {
    // left: -16px !important;
    transition: all 0.6s;
    .layout--reset {
      visibility: visible;
      opacity: 1;
      width: unset;
      transition: all 0.6s;
    }
    .layout--task,
    .layout--gantt {
      opacity: 0;
      visibility: hidden;
      opacity: 0;
      width: 0;
      min-width: 0;
      transition: all 0.6s;
    }
  }
  &:not(.--isActive) {
    .layout--reset {
      opacity: 0;
      visibility: hidden;
      opacity: 0;
      width: 0;
      transition: all 0.6s;
    }
    .layout--task,
    .layout--gantt {
      visibility: visible;
      opacity: 1;
      width: unset;
      transition: all 0.6s;
    }
  }
  & > li {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    button {
      width: 100%;
    }
    &.layout--task {
      margin-right: 4px;
      min-width: 40px;
      button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .anticon-arrow-left {
        margin-left: 24px !important;
        -webkit-transition: inherit;
        transition: inherit;
      }
      .anticon-signal {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: inherit;
        transition: inherit;
        width: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      &:hover {
        .anticon-arrow-left {
          margin-left: 0px !important;
          transition: inherit;
        }
        .anticon-signal {
          margin-left: 2px !important;
          visibility: visible;
          opacity: 1;
          width: unset;
          -webkit-transition: all 0.2s; /* For Safari 3.1 to 6.0 */
          transition: all 0.2s;
        }
      }
    }
    &.layout--gantt {
      margin-left: 4px;
      min-width: 40px;
      display: flex;
      justify-content: flex-end;
      button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .anticon-arrow-right {
        margin-right: 24px !important;
        -webkit-transition: inherit;
        transition: inherit;
      }
      .anticon-database {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: inherit;
        transition: inherit;
        width: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      &:hover {
        .anticon-arrow-right {
          margin-right: 0px !important;
          -webkit-transition: inherit;
          transition: inherit;
        }
        .anticon-database {
          margin-right: 2px !important;
          visibility: visible;
          opacity: 1;
          width: unset;
          -webkit-transition: all 0.2s; /* For Safari 3.1 to 6.0 */
          transition: all 0.2s;
        }
      }
    }
  }
  .layout--reset {
    // margin-top: -10px;
    button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: red;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

div[data-column-name='status'] {
  .gantt_tree_content {
    display: flex;
    justify-content: space-between;
    & > div {
      flex-basis: 33.3333%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .task-status {
        margin: 0;
        width: 17px;
        height: 17px;
        line-height: 17px;
        font-size: 12px;
      }
      .anticon {
        font-size: 15px;
        // &.anticon-check-circle {
        //   font-size: 8px;
        //   position: absolute;
        //   left: 50%;
        //   top: 50%;
        //   margin-left: -2px;
        //   margin-top: 2px;
        //   transform: translate(-50%, -50%);
        // }
      }
    }
  }
}

div[data-column-id='add'] {
  &.gantt_grid_head_cell {
    display: inline-flex !important;
  }
}

.gantt_tree_indent {
  width: 8px;
}
.gantt_tree_icon {
  width: 22px;
  &.gantt_folder_open,
  &.gantt_file {
    background-size: 15px;
  }
  &.gantt_blank {
    width: 15px;
  }
}
.gantt_task_cell.week_end {
  background-color: #eff5fd;
}

.gantt_task_row.gantt_selected .gantt_task_cell.week_end {
  background-color: #f6e994;
}
