//
//  TYPOGRAPHY
//––––––––––––––––––––––––––––––––––––––––––––––––––
/**
  * Calculate the rem value based on the px value
  *
  * @param $fontsize
  * @return number in rem
  */
@function calculateRem($fontSize) {
  $remSize: $fontSize / 16px;
  @return #{$remSize}rem;
}

/**
 * Convert px to rem for font size with fallback
 */
@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}
/**
 * Convert px to rem for line-height with fallback
 */
@mixin lineHeight($size) {
  line-height: $size;
  line-height: calculateRem($size);
}

/**
 * Add color modifier based on ant
 */
@mixin add-color-modifier($className, $color) {
  .color--#{$className}.ant-typography *,
  .color--#{$className} {
    color: $color;
  }
}

/**
 * Add fontSize modifier based on ant
 */
@mixin add-fontSize-modifier($heading, $size) {
  #{$heading}.ant-typography,
  .ant-typography #{$heading} {
    @include fontSize($size);
  }
}

/**
 * Add body text style
 */
@mixin style-body-text() {
  @include fontSize($fp);
  font-weight: 300;
  font-family: $family-body;
  webkit-font-smoothing: antialiased;
}

//
//  MEDIA QUERY
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints based on ant
// $breakpoints: (
//     xs:  320px,
//     sm:  576px,
//     md:  768px,
//     lg:  992px,
//     xl:  1200px,
//     xxl: 1600px
//   );

$xs: 20em;
$sm: 36em;
$md: 48em;
$lg: 64em;
$xl: 75em;
$xxl: 100em;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin respondAbove($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respondBelow($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respondBetween($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
