@import '../base.scss';
@import './space';

.layout-view {
  min-height: 100vh;
}
.layout-content {
  margin: 24px;
}
.content {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  &.container {
    @media only screen and (max-width: $tablet) {
      margin-left: 150px;
      margin-right: 150px;
    }
    @media only screen and (max-width: $mobile) {
      margin-left: 25px;
      margin-right: 25px;
    }
    margin-left: 250px;
    margin-right: 250px;
  }
}
.pointer {
  cursor: pointer !important;
}
.pointer-events--none {
  pointer-events: none;
  & a {
    cursor: default;
    text-decoration: none;
    color: inherit;
  }
}
.nav-bar {
  // background-color: white;
  // border-bottom: 1px solid #bebebe56;
  padding: 0 25px !important;
  position: relative;
  z-index: 10;
  // max-width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  @media only screen and (max-width: $tablet) {
    padding: 0 10px !important;
  }
  .logo-header {
    height: 40px;
    @media only screen and (max-width: $mobile-tiny) {
      height: unset;
      max-width: 100%;
    }
    // margin: 16px;
    // background-color: white;
  }
  .ant-menu-item.ant-menu-item-selected {
    background-color: yellowgreen;
  }
}
.ant-layout-header {
  height: 48px;
  line-height: 48px;
}
.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-green {
  color: $green !important;
}
.bg-green {
  background-color: $green !important;
}
.ant-typography {
  &.ant-typography-disabled {
    pointer-events: none;
  }
  &.bold {
    font-weight: 700 !important;
  }
  &.medium {
    font-weight: 500 !important;
  }
}
.divider {
  display: flex;
  margin-bottom: 8px;
  &-line {
    border-top: 1px solid #f0f0f0;
    flex: 1;
    margin: 15px 10px;
  }
}
.filters-group {
  margin-bottom: 20px;
}
.pull-right {
  float: right;
}
.form-control {
  margin-bottom: 20px;
}
/* left sidebar */
.site-layout-background {
  background: #fff;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  border-right: 1px solid #f0f0f0 !important;
  .ant-menu-item {
    font-weight: 600;
    &.ant-menu-item-selected {
      background-color: #d6e4d5;
    }
  }
  .ant-menu-submenu-title {
    font-weight: 600;
  }
}
.ant-layout-sider-trigger {
  background-color: #d6e4d5 !important;
  color: $green;
}
.fixed-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}
.select-option-group-item {
  cursor: pointer;
  font-size: 14px;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-bold {
  font-weight: bold;
}
.ant-typography.ant-typography-success {
  color: #52c41a;
}
.pointer {
  cursor: pointer;
}
.ant-select-dropdown {
  &.select-fit-text {
    width: auto !important;
  }
}
