@import './abstracts/mixins';

$desktop-small: $xl;
$tablet: $lg;
$mobile: $md;
$mobile-small: $sm;
$mobile-tiny: $xs;

//Define colours
$green: #357638;
$pink: #e85eab;
$light-pink: #f6c0d7;
$yellow: #f9cf57;
$light-blue: #56c8d3;
$orange: #faa773;
$light-green: #72ecb5;
$purple: #bd97cd;
$red: #ed5371;
$goblin: #357638;

//General
$accent: $goblin;

//Define Fontsize Variables
$f1: 38px;
$f2: 30px;
$f3: 24px;
$f4: 20px;
$f5: 16px;
$f6: 14px;
$fp: 14px;

// margin
$m-xxs: 5px;
$m-xs: 10px;
$m-sm: 15px;
$m-sd: 20px; // sd == standard
$m-md: 25px;
$m-lg: 30px;
$m-xl: 35px;
$m-xxl: 40px;
$m-zero: 0;

// margin sizing
.m-xxs {
  margin: $m-xxs !important;
}
.m-xs {
  margin: $m-xs !important;
}
.m-sm {
  margin: $m-sm !important;
}
.m-sd {
  margin: $m-sd !important;
}
.m-md {
  margin: $m-md !important;
}
.m-lg {
  margin: $m-lg !important;
}
.m-xl {
  margin: $m-xl !important;
}
.m-xxl {
  margin: $m-xxl !important;
}
.m-0 {
  margin: $m-zero !important;
}

.mt-xxs {
  margin-top: $m-xxs !important;
}
.mt-xs {
  margin-top: $m-xs !important;
}
.mt-sm {
  margin-top: $m-sm !important;
}
.mt-sd {
  margin-top: $m-sd !important;
}
.mt-md {
  margin-top: $m-md !important;
}
.mt-lg {
  margin-top: $m-lg !important;
}
.mt-xl {
  margin-top: $m-xl !important;
}
.mt-xxl {
  margin-top: $m-xxl !important;
}
.mt-0 {
  margin-top: $m-zero !important;
}

.mr-xxs {
  margin-right: $m-xxs !important;
}
.mr-xs {
  margin-right: $m-xs !important;
}
.mr-sm {
  margin-right: $m-sm !important;
}
.mr-sd {
  margin-right: $m-sd !important;
}
.mr-md {
  margin-right: $m-md !important;
}
.mr-lg {
  margin-right: $m-lg !important;
}
.mr-xl {
  margin-right: $m-xl !important;
}
.mr-xxl {
  margin-right: $m-xxl !important;
}
.mr-0 {
  margin-right: $m-zero !important;
}

.mb-xxs {
  margin-bottom: $m-xxs !important;
}
.mb-xs {
  margin-bottom: $m-xs !important;
}
.mb-sm {
  margin-bottom: $m-sm !important;
}
.mb-sd {
  margin-bottom: $m-sd !important;
}
.mb-md {
  margin-bottom: $m-md !important;
}
.mb-lg {
  margin-bottom: $m-lg !important;
}
.mb-xl {
  margin-bottom: $m-xl !important;
}
.mb-xxl {
  margin-bottom: $m-xxl !important;
}
.mb-0 {
  margin-bottom: $m-zero !important;
}

.ml-xxs {
  margin-left: $m-xxs !important;
}
.ml-xs {
  margin-left: $m-xs !important;
}
.ml-sm {
  margin-left: $m-sm !important;
}
.ml-sd {
  margin-left: $m-sd !important;
}
.ml-md {
  margin-left: $m-md !important;
}
.ml-lg {
  margin-left: $m-lg !important;
}
.ml-xl {
  margin-left: $m-xl !important;
}
.ml-xxl {
  margin-left: $m-xxl !important;
}
.ml-0 {
  margin-left: $m-zero !important;
}

// padding sizing
.p-xxs {
  padding: $m-xxs !important;
}
.p-xs {
  padding: $m-xs !important;
}
.p-sm {
  padding: $m-sm !important;
}
.p-sd {
  padding: $m-sd !important;
}
.p-md {
  padding: $m-md !important;
}
.p-lg {
  padding: $m-lg !important;
}
.p-xl {
  padding: $m-xl !important;
}
.p-xxl {
  padding: $m-xxl !important;
}
.p-xxl {
  padding: $m-xxl !important;
}
.p-0 {
  padding: $m-zero !important;
}

.pt-xxs {
  padding-top: $m-xxs !important;
}
.pt-xs {
  padding-top: $m-xs !important;
}
.pt-sm {
  padding-top: $m-sm !important;
}
.pt-sd {
  padding-top: $m-sd !important;
}
.pt-md {
  padding-top: $m-md !important;
}
.pt-lg {
  padding-top: $m-lg !important;
}
.pt-xl {
  padding-top: $m-xl !important;
}
.pt-xxl {
  padding-top: $m-xxl !important;
}
.pt-0 {
  padding-top: $m-zero !important;
}

.pr-xxs {
  padding-right: $m-xxs !important;
}
.pr-xs {
  padding-right: $m-xs !important;
}
.pr-sm {
  padding-right: $m-sm !important;
}
.pr-sd {
  padding-right: $m-sd !important;
}
.pr-md {
  padding-right: $m-md !important;
}
.pr-lg {
  padding-right: $m-lg !important;
}
.pr-xl {
  padding-right: $m-xl !important;
}
.pr-xxl {
  padding-right: $m-xxl !important;
}
.pr-0 {
  padding-right: $m-zero !important;
}

.pb-xxs {
  padding-bottom: $m-xxs !important;
}
.pb-xs {
  padding-bottom: $m-xs !important;
}
.pb-sm {
  padding-bottom: $m-sm !important;
}
.pb-sd {
  padding-bottom: $m-sd !important;
}
.pb-md {
  padding-bottom: $m-md !important;
}
.pb-lg {
  padding-bottom: $m-lg !important;
}
.pb-xl {
  padding-bottom: $m-xl !important;
}
.pb-xxl {
  padding-bottom: $m-xxl !important;
}
.pb-0 {
  padding-bottom: $m-zero !important;
}

.pl-xxs {
  padding-left: $m-xxs !important;
}
.pl-xs {
  padding-left: $m-xs !important;
}
.pl-sm {
  padding-left: $m-sm !important;
}
.pl-sd {
  padding-left: $m-sd !important;
}
.pl-md {
  padding-left: $m-md !important;
}
.pl-lg {
  padding-left: $m-lg !important;
}
.pl-xl {
  padding-left: $m-xl !important;
}
.pl-xxl {
  padding-left: $m-xxl !important;
}
.pl-0 {
  padding-left: $m-zero !important;
}
@mixin truncated-text {
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 140%;
  letter-spacing: 0px;
  min-height: 40px;
  padding: 0;
  text-align: left;
}
// .--truncated {
//   white-space: pre-wrap;
//   display: flex;
//   align-items: center;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 2;
//   line-height: 140%;
//   letter-spacing: 0px;
//   min-height: 40px;
//   padding: 0;
//   text-align: left;
// }
