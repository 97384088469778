@import './../../assets/scss/base.scss';

.loading {
  position: absolute;
  top: 40%;
  left: 48%;
  .ant-spin-dot {
    font-size: 80px !important;
    .ant-spin-dot-item {
      width: 35px !important;
      height: 35px !important;
    }
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: rgba(250, 250, 250, 0.5);
}
