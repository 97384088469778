@import '../../../assets/scss/base.scss';

.ant-menu {
  .logo-link {
    display: block;
    @include respondBelow(md) {
      display: block;
      text-align: center;
    }
    .logo-header {
      max-width: 100%;
      margin: 0 auto 0.5rem;
      padding: 0.2rem 20px 0.2rem 24px;
      max-height: 4rem;
      @include respondBelow(md) {
        max-height: 8rem;
      }
    }
  }

  &.ant-menu-inline-collapsed {
    .logo-header {
      padding: 0.2rem;
      max-height: 100%;
    }
  }
}

.ant-layout-sider-zero-width-trigger {
  background: #357638 !important;
  // .anticon-bars {
  //   color: #000000;
  // }
  &:hover {
    background: #649666 !important;
  }
  // @include respondBelow(md) {
  //   display: none !important;
  // }
  display: none;
}

.menu-drawer {
  .ant-drawer-body {
    padding: 0;
  }
  .menu-drawer-trigger {
    position: absolute;
    right: -20px;
    top: 2rem;
    z-index: 999;
  }
  .ant-drawer-content {
    overflow: initial;
  }
}

aside {
  &.ant-layout-sider {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
    @media only screen and (max-width: $tablet) {
      position: absolute;
      height: 100vh;
      z-index: 999;
    }
    // &.opened {
    //   @media only screen and (max-width: $tablet) {
    //     & + .ant-layout {
    //       &::before {
    //         position: absolute;
    //         width: 100%;
    //         height: 100%;
    //         z-index: 998;
    //         background-color: rgba(0, 0, 0, 0.4);
    //       }
    //     }
    //   }
    // }
  }
}
.ant-layout-sider-collapsed {
  border-right: 1px solid #f0f0f0 !important;
}
.logo-container {
  padding: 10px 20px 0;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
  img {
    max-height: 60px;
    @include respondBelow(sm) {
      max-height: 4rem;
    }
  }
}
