@import './base.scss';
@import './abstracts/mixins';
@import './base/common';
@import './base/typography';

html {
  font-size: 100% !important;

  // @include respondAbove(xl) {
  //   font-size: 110% !important;
  // }

  // @media only screen and (max-width: $tablet) {
  //   font-size: 85% !important;
  // }

  @include respondBelow(sm) {
    min-height: 100vh;
  }

  .layout-main {
    & > .layout-content {
      margin: 0;
    }
  }
}
