@import '../../assets/scss/base.scss';

.ant-modal {
  @include respondAbove(xs) {
    top: 6rem !important;
  }
  @include respondAbove(md) {
    top: 100px !important;
  }
  &.modal-relation {
    .ant-modal-content {
      .ant-modal-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .modal-button--delete {
          margin-right: auto;
        }
      }
    }
  }
}
.task-modal {
  .ant-modal-footer {
    padding: 10px 0px !important;
  }
  .ant-modal-body {
    padding-top: 0px !important;
  }
  .modal-relation {
    margin-bottom: 2rem;
    & > .ant-row {
      & > .ant-col {
        margin-bottom: 0;
        &:last-child {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          button {
            padding: 0 0.4rem;
            font-size: 0.8rem;

            line-height: 140%;
            &:first-child {
              flex-grow: 1;
              margin-right: 5px;
            }
          }
        }
        & > .ant-row {
          margin-bottom: 0;
        }
      }
    }
  }
}
.select-status {
  &.new {
    .ant-select-selector {
      background-color: $light-green !important;
      border: 1px solid $light-green !important;
    }
  }
  &.closed {
    .ant-select-selector {
      background-color: $red !important;
      border: 1px solid $red !important;
    }
  }
  &.in-progress {
    .ant-select-selector {
      background-color: $light-blue !important;
      border: 1px solid $light-blue !important;
    }
  }
  &.approved {
    .ant-select-selector {
      background-color: $yellow !important;
      border: 1px solid $yellow !important;
    }
  }
  &.rejected {
    .ant-select-selector {
      background-color: $purple !important;
      border: 1px solid $purple !important;
    }
  }
  &.done {
    .ant-select-selector {
      background-color: $orange !important;
      border: 1px solid $orange !important;
    }
  }
}
.pre-line {
  white-space: pre-line
}
.old-value {
  color: red;
}
.new-value {
  color: green;
}
.field-name {
  font-style: italic;
  font-weight: bold;
}