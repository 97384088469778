body {
  @include fontSize($fp);
  $family-title: inherit;
  $family-body: inherit;

  @include add-fontSize-modifier(h1, $f1);
  @include add-fontSize-modifier(h2, $f2);
  @include add-fontSize-modifier(h3, $f3);
  @include add-fontSize-modifier(h4, $f4);
  @include add-fontSize-modifier(h5, $f5);
  @include add-fontSize-modifier(h6, $f6);

  h1 {
    font-family: $family-title;
    -webkit-font-smoothing: antialiased;
  }
  h2 {
    font-family: $family-title;
    -webkit-font-smoothing: antialiased;
  }
  h3 {
    font-family: $family-title;
    -webkit-font-smoothing: antialiased;
  }
  h4 {
    font-family: $family-body;
    -webkit-font-smoothing: antialiased;
  }
  h5 {
    font-family: $family-body;
    -webkit-font-smoothing: antialiased;
  }
}
